


























































































































import Vue from "vue";
import firebase from "@/plugins/firebase";
import "@/types";

export default Vue.extend({
  data: () => ({
    form: {
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
      email: "",
      phone: ""
    },
    alert: false,
    message: "",
    error: false,
    confirm: false
  }),
  computed: {
    firstNameRules() {
      return [(v: string) => !!v || "Lütfen adınızı yazınız"];
    },
    lastNameRules() {
      return [(v: string) => !!v || "Lütfen soyadınızı yazınız"];
    },
    emailRules() {
      return [
        (v: string) => !!v || "Lütfen e-postanızı yazınız",
        (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
      ];
    },
    phoneRules() {
      return [
        (v: string) => !!v || "Lütfen telefon numaranızı yazınız",
        (v: string) =>
          /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/.test(v) ||
          "Geçersiz telefon numarası"
      ];
    },
    passwordRules() {
      return [
        (v: string) => !!v || "Lütfen parolanızı yazınız",
        (v: string) =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm.test(v) ||
          "Parolanız en az 8 karakter olmalıdır. En az 1 büyük harf, 1 küçük harf ve 1 sayı içermelidir."
      ];
    },
    passwordConfirmRules() {
      return [
        (v: string) => !!v || "Lütfen parolanızı onaylayınız",
        (v: string) => v === this.form.password || "Parola onaylanmadı"
      ];
    },
    confirmRules() {
      return [
        this.confirm ||
          "Lütfen kullanım koşullarını ve politikalarını onaylayınız."
      ];
    }
  },
  methods: {
    validate() {
      const form = this.$refs.form as HTMLFormElement;
      return form.validate();
    },
    reset() {
      const form = this.$refs.form as HTMLFormElement;
      form.reset();
    },
    resetValidation() {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    },
    signup() {
      if (this.validate()) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.form.email, this.form.password)
          .then(userRef => {
            if (!userRef.user) return;

            // Update firebase auth profile
            userRef.user.updateProfile({
              displayName: `${this.form.firstName} ${this.form.lastName}`
            });

            const newUser: User = {
              id: userRef.user.uid,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              displayName: `${this.form.firstName} ${this.form.lastName}`,
              email: this.form.email,
              phone: this.form.phone,
              roles: ["student"]
            };

            firebase
              .firestore()
              .collection("/apps/kahev-akademi/users")
              .add({
                uid: newUser.id,
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                email: newUser.email,
                phone: newUser.phone,
                roles: newUser.roles
              })
              .then(() => {
                this.$store.dispatch("auth/signIn", newUser);

                this.error = false;
                this.alert = true;
                this.message =
                  "Kullanıcı kaydınız başarıyla tamamlandı. Lütfen bekleyin...";

                this.$router.push({ name: "home" });
              })
              .catch(error => {
                throw `Kullanıcı profili oluşturulamadı: ${error}`;
              });
          })
          .catch(err => {
            this.error = true;
            this.alert = true;
            this.message = this.getErrorMessage(err.code);
          });
      }
    },
    getErrorMessage(code: string) {
      let msg = "";
      switch (code) {
        case "auth/email-already-in-use":
          msg =
            "Bu e-posta adresi başka bir hesap tarafından zaten kullanılıyor.";
          break;
        case "auth/invalid-email":
          msg = "e-Posta adresi geçersiz.";
          break;
        case "auth/operation-not-allowed":
          msg = "Bu işlemi gerçekleştirmek için izniniz yok.";
          break;
        case "auth/weak-password":
          msg = "Parolanız en az 6 karakter uzunluğunda olmalıdır.";
          break;
        default:
          msg =
            "HATA! Kullanıcı kaydı tamamlanamadı. Lütfen daha sonra tekrar deneyiniz.";
      }
      return msg;
    }
  }
});
